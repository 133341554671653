<template>
  <v-card :loading="loading">
    <v-toolbar flat>
      <v-toolbar-title>Registration Management</v-toolbar-title>
      <v-spacer />
      <v-form @submit.stop.prevent="fetchData">
        <v-text-field
          class="mr-2"
          v-model="search"
          label="Filter"
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          @click:clear="onClear"
        />
      </v-form>
    </v-toolbar>
    <v-card-text>
      <ApiError :errors="errors" />
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1"
        item-key="id"
        :loading="loading"
        :options.sync="options"
        :server-items-length="total"
        :footer-props="{ 'items-per-page-options': [10, 25, 50] }"
        v-if="this.items"
      >
        <template v-slot:item.createdDate="{ item }">
          <span> {{ item.createdDate | formatDateClient("MM/DD/YYYY", selectedClient) }}</span>
        </template>
        <template v-slot:item.organization.name="{ item }">
          <div>
            {{ item.organization.name }}
          </div>
          <div class="pl-5">
            {{ item.organization.address ? item.organization.address.cityRegionPostalCountry : "" }}
          </div>
          <div class="pl-5">{{ item.organization.phoneNumber1 }}</div>
        </template>
        <template v-slot:item.user.lastName="{ item }">
          <div>
            {{ item.fullName }}
          </div>
          <div class="pl-5">{{ item.address ? item.address.cityRegionPostalCountry : "" }}</div>
          <div class="pl-5">{{ item.phoneNumber1 }}</div>
        </template>
        <template v-slot:item.distributors="{ item }">
          <div v-for="relatedOrganization in item.relatedOrganizations" :key="relatedOrganization.id">
            {{ relatedOrganization.relatedOrganization.name }}
            ({{ relatedOrganization.accountNumber }})
          </div>
        </template>
        <template v-slot:item.approve="{ item }">
          <v-btn class="ma-2" text icon color="blue lighten-2" @click="openApproveDialog(item)">
            <v-icon>mdi-thumb-up</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.reject="{ item }">
          <v-btn class="ma-2" text icon color="red lighten-2" @click="openRejectDialog(item)">
            <v-icon>mdi-thumb-down</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-dialog v-model="approveDialog" max-width="600px" persistent>
        <v-card>
          <v-card-title class="headline">Approve Registration?</v-card-title>
          <v-card-text>
            <p>
              The registration request for
              <b>{{ itemSelected.organization ? itemSelected.organization.name : "" }} </b> will be Approved. An email
              to the Retail Sales Rep will be sent with instructions on creating an account.
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="close">{{ $i18n.translate("Cancel") }}</v-btn>
            <v-btn color="primary" @click="approve" :loading="loadingApprove">Approve</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="rejectDialog" max-width="600px">
        <v-form @submit.prevent="reject" v-model="reasonValid">
          <v-card>
            <v-card-title>
              <span class="headline"> Reject Registration? </span>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <p>
                    The registration request for
                    <b>{{ itemSelected.organization ? itemSelected.organization.name : "" }} </b> will be Rejected. An
                    email to the Principal Owner will be sent with any comments you provide below.
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    label="Comments"
                    name="reason"
                    id="reason"
                    v-model="enrollRejectForm.reason"
                    :rules="rules.reason"
                    :error-messages="$error.getValidationError(errors, 'reason')"
                    @input="$error.clearValidationError(errors, 'reason')"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn @click="close()">{{ $i18n.translate("Cancel") }}</v-btn>
              <v-btn color="primary" @click="reject" :loading="loadingReject" :disabled="!reasonValid">Reject</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import ApiError from "@/gapp-components/components/display/ApiError.vue";
import { mapGetters } from "vuex";

export default {
  components: { ApiError },
  name: "CprRegistrationManagement",
  metaInfo: {
    title: "Registration Management"
  },
  data: function() {
    return {
      loading: false,
      loadingApprove: false,
      loadingReject: false,
      search: "",
      approveDialog: false,
      rejectDialog: false,
      enrollRejectForm: {},
      reasonValid: true,
      headers: [
        { text: "Register Date", value: "createdDate" },
        { text: "Distributor", value: "distributors" },
        { text: "Company", value: "organization.name" },
        { text: "Retail Sales Rep Name", value: "user.lastName" },
        { text: "Retail Sales Rep Email", value: "user.username" },
        { text: "Approve", value: "approve", sortable: false, align: "center" },
        { text: "Reject", value: "reject", sortable: false, align: "center" }
      ],
      rules: {
        reason: [v => !!v || "An explanation is required"]
      },
      items: [],
      itemSelected: { user: {} },
      errors: {},
      options: {
        itemsPerPage: 10
      },
      total: 0,
      filterByParticipantType: {}
    };
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true
    }
  },
  methods: {
    onClear() {
      this.search = "";
      this.fetchData();
    },
    getData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let promise = null;
      if (this.selectedParticipant.participantType.participantTypeKey != "100") {
        promise = this.$api.post(
          "/api/participants/search/byManaged?status=PRE&size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          {}
        );
      } else {
        promise = this.$api.post(
          "/api/participants/search?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          { status: { name: "PRE" } }
        );
      }
      promise
        .then(({ data }) => {
          this.items = data.content;
          this.total = data.totalElements;
          this.items
            .reduce((p, item) => {
              return p.then(() => this.getRelatedOrganizationData(item));
            }, Promise.resolve())
            .then(() => {
              this.loading = false;
              this.$forceUpdate();
            });
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getRelatedOrganizationData(item) {
      return this.$api
        .post("/api/relatedOrganizations/search", { organization: { id: item.organization.id } })
        .then(({ data }) => {
          item.relatedOrganizations = data.content;
          return Promise.resolve(item);
        });
    },

    close() {
      this.approveDialog = false;
      this.rejectDialog = false;
      this.enrollRejectForm.reason = undefined;
    },

    openApproveDialog(item) {
      this.itemSelected = item;
      this.approveDialog = true;
    },
    reject() {
      this.loadingReject = true;
      let serviceCall = null;
      let postForm = {
        reason: this.enrollRejectForm.reason,
        rejectedUser: this.selectedParticipant.user,
        emailTemplateParticipantForm: {
          emailTemplateKey: "APPROVAL_INDIRECT_DECLINED"
        }
      };
      serviceCall = this.$api.post("/api/participants/reject/" + this.itemSelected.id, postForm);
      serviceCall
        .then(() => {
          this.loadingReject = false;
          this.close();
          this.getData();
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        });
    },
    openRejectDialog(item) {
      this.itemSelected = item;
      this.rejectDialog = true;
      this.enrollRejectForm.reason = undefined;
    },
    approve() {
      this.loadingApprove = true;
      let serviceCall = null;
      let postForm = {
        approvedUser: this.selectedParticipant.user,
        emailTemplateParticipantForm: {
          emailTemplateKey: "APPROVAL_INDIRECT_APPROVED"
        }
      };
      serviceCall = this.$api.post("/api/participants/approve/" + this.itemSelected.id, postForm);
      serviceCall
        .then(() => {
          this.close();
          this.getData();
          this.loadingApprove = false;
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        });
    }
  },
  mounted() {
    this.$store.dispatch("setBreadcrumb", [
      {
        text: this.$i18n.translate("Dashboard"),
        to: { name: "dashboard" },
        exact: true
      },
      { text: "Admin" },
      { text: "Registration Management" }
    ]);
  },
  computed: {
    ...mapGetters(["selectedClient", "selectedProgram", "selectedParticipant"])
  }
};
</script>
